import {
  Autocomplete,
  Avatar,
  Button,
  Flex,
  Group,
  Text,
  TextInput,
  Tooltip,
  UnstyledButton,
  Box
} from "@mantine/core";
import { IconGripVertical, IconPlus } from "@tabler/icons";
import React, { forwardRef, useState } from "react";
import { SourceIcon } from "../../../utils/SourceIcon";
import { useForm } from "@mantine/form";
import { useAuth0 } from "@auth0/auth0-react";
import { showNotification } from "@mantine/notifications";
import {updateProductArea, changeParent} from "../../../api/product-areas";
import ConfirmOwnerChangeModal from './ConfirmOwnerChangeModal';
import AreaReorderModal from "./AreaReorderModal"
 
interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  picture: string;
  name: string;
  email: string;
}

interface DomainItemProps extends React.ComponentPropsWithoutRef<"div"> {
  name: string;
  value: string;
}

function RowItem({
  element,
  isChild,
  members,
  isFirst,
  setRemovedRow,
  elements,
  index,
  domains,
  handleRemoveValue,
  parent,
  loadPage,
  setLoadPage,
  setLoading,
}: {
  element: any;
  isChild: boolean;
  members: any;
  isFirst?: boolean;
  setRemovedRow: any;
  elements: any;
  index: number;
  domains: any;
  handleRemoveValue: (id: string)=> void
  parent?: any
  loadPage: boolean;
  setLoadPage: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: any,
}) {
  const auth0 = useAuth0();
  const [onEdit, setOnEdit] = useState<boolean>(false);
  const [childItem, setChildItem] = useState(isChild);
  const [clickAble, setClickable] = useState(true)
  const [opened, setOpened] = useState(false);
  const [openAreaReorderModal, setOpenAreaReorderModal] = useState(false);
  const form = useForm({
    initialValues: {...element},
  });

  const [selectedOwner, setSelectedOwner] = useState(form.values.ownerName);
  const oldName = element.name

  const saveNotification = () => {
    showNotification({
      title: "Your settings have been saved!",
      message: "",
      icon: <SourceIcon sourceName="tick-circle" />,
      autoClose: 1200
    });
  };
  const domainWarningNotification = () => {
    showNotification({
      title: "Please select a domain first!",
      message: "",
      icon: <SourceIcon sourceName="tick-circle" />,
      autoClose: 1200
    });
  };

  const handleEdit = async (id: string) => {
    setOnEdit(true);
    const formElements = {...element};
    formElements.accountId && delete formElements.accountId
    formElements._id && delete formElements._id
    formElements.childValues && delete formElements.childValues
    console.log({formElements})
    await form.setValues({...formElements});
    console.log(form.values)
  };

  const saveProductArea = async (updateData: any, productAreaId: string) => {
    try {
      await updateProductArea(auth0, productAreaId, updateData)
      saveNotification();
    } catch (e) {
      console.log(e);
    }
  };

  const updateOwner = (
    formValues: any,
    elementId: string,
    ownerName: string,
    ownerId: string
  ) => {
    formValues.ownerName = ownerName;
    formValues.ownerId = ownerId;

    saveProductArea(formValues, elementId);
  };

  const updateDomain = (
    formValues: any,
    elementId: string,
    domainName: string
  ) => {
    formValues.domain = domainName;
    saveProductArea(formValues, elementId);
    setLoadPage(!loadPage); 
  };

  const handleSave = (id: string, formValues: any) => {
    element.name = formValues.name;
    element.description = formValues.description;
    element.ownerName = formValues.ownerName;
    element.ownerId = formValues.ownerId;

    setOnEdit(false);
    saveProductArea(formValues, id);
  };

  const handleRemoveIndentation = async (
    componentId: string,
    child: boolean,
    parentId: string,
    moveAfter: string,
  ) => {
    try {
      const body = { componentId, child, parentId, moveAfter };
      await changeParent(auth0, body).then((res) => {
        console.log(res);
        setLoadPage(!loadPage)
        saveNotification()
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleToggleIndentation = (child: boolean, id: string, parentId: string, moveAfter: string) => {
    setChildItem(!child);
    if (child === true) {
      handleRemoveIndentation(id, child, "", moveAfter);
    } else {
      handleRemoveIndentation(id, child, parentId, moveAfter);   
    }
    setClickable(!clickAble)
  };

  const handleRemove = (id: string) => {
    handleRemoveValue(id)
  };

  const handleOwnerChange = (item: any) => {
    setSelectedOwner(item);
    setOpened(true);
  };

  const handleConfirm = () => {
    form.setFieldValue("ownerId", selectedOwner._id);
    form.setFieldValue("ownerName", selectedOwner.name);
    updateOwner(form.values, element._id, selectedOwner.name, selectedOwner._id);
    setOpened(false); 
  };

  const handleCancel = () => {
    setOpened(false);
  };

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ picture, name, email, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        {members && (
          <Group noWrap>
            <Avatar src={picture} radius="xl" />

            <div>
              <Text size="sm">{name}</Text>
              <Text size="xs" opacity={0.65}>
                {email}
              </Text>
            </div>
          </Group>
        )}
      </div>
    )
  );

  const DomainItem = forwardRef<HTMLDivElement, DomainItemProps>(
    ({ name, value, ...others }: DomainItemProps, ref) => (
      <div ref={ref} {...others}>
        {members && (
          <Group noWrap>
            <div>
              <Text size="sm">{name}</Text>
              <Text size="xs" opacity={0.65}>
                {value}
              </Text>
            </div>
          </Group>
        )}
      </div>
    )
  );

  return (
    <>
      <tr key={element._id}>
        <td
          style={{
            width: "10px",
            textAlign: "center",
            paddingLeft: "30px",
            paddingRight: "-30px",
            marginRight: "-30px",
            cursor: "pointer",
          }}
          onClick={() => element.domain ? setOpenAreaReorderModal(true) : domainWarningNotification() }
        >
          <Tooltip label="Rearrange" position="top" withArrow withinPortal>
            <Box style={{ cursor: "pointer", display: "inline-block" }}>
              <SourceIcon sourceName="reorder" />
            </Box>
          </Tooltip>
        </td>
        <td
          style={{
            width: "10px",
            textAlign: "left",
            paddingLeft: "0px",
            paddingRight: 0,
          }}
        >
          {childItem === true ? (
            <></>
          ) : (
            <>
              {isFirst ? (
                <IconGripVertical size="1.05rem" stroke={1.5} />
              ) : (
                <>
                  <IconGripVertical size="1.05rem" stroke={1.5} />
                  <Tooltip label="Convert to Product sub-area" withArrow>
                    <UnstyledButton
                      disabled={!clickAble}
                      p={0}
                      m={0}
                      onClick={() =>{
                        setClickable(!clickAble)
                        handleToggleIndentation(childItem, element._id, parent?._id, '')
                      }
                        
                      }
                    >
                      <SourceIcon sourceName={"arrow-right"} />
                    </UnstyledButton>
                  </Tooltip>
                </>
              )}
            </>
          )}
        </td>
        <td
          width={"150px"}
          style={{
            textAlign: childItem === true ? "center" : "left",
          }}
          {...form.getInputProps("name")}
        >
          {childItem === true ? (
            <Group>
              <Flex>
                <IconGripVertical size="1.05rem" stroke={1.5} />
                <Tooltip label="Convert back to Product Area" withArrow>
                  <UnstyledButton
                    py={0}
                    pl={5}
                    m={0}
                    onClick={() =>
                      handleToggleIndentation(childItem, element._id,parent?._id, '')
                    }
                  >
                    <SourceIcon sourceName={"arrow-left"} />
                  </UnstyledButton>
                </Tooltip>
              </Flex>
              <>
                {onEdit ? (
                  <TextInput {...form.getInputProps("name")} />
                ) : (
                  element.name
                )}
              </>
            </Group>
          ) : (
            <>
              {onEdit ? (
                <TextInput {...form.getInputProps("name")} />
              ) : (
                element.name
              )}
            </>
          )}
        </td>
        <td width={"150px"} style={{ textAlign: "left" }}>
          {onEdit ? (
            <TextInput
              {...form.getInputProps("description")}
              placeholder="Add description here..."
            />
          ) : element.description?.length > 0 ? (
            element.description
          ) : (
            <Text c="dimmed">No description yet...</Text>
          )}
        </td>
        <td width={"150px"}>
          {members && members.length > 0 && (
            <Autocomplete
              placeholder="Select owner"
              itemComponent={SelectItem}
              value={form.values.ownerName || ""}
              onChange={(val) => {
                form.setFieldValue("ownerName", val);
              }}
              onItemSubmit={handleOwnerChange}
              data={members}
              nothingFound={
                <Button fullWidth disabled leftIcon={<IconPlus size={18} />}>
                  Invite member
                </Button>
              }
              filter={(value, user) =>
                (user.name &&
                  user.name.toLowerCase().includes(value.toLowerCase().trim())) ||
                (user.email &&
                  user.email.toLowerCase().includes(value.toLowerCase().trim()))
              }
              error={form.errors.ownerName || form.errors.ownerId}
            />
          )}
          {/* Model for owner change */}
          <ConfirmOwnerChangeModal
            opened={opened}
            elementName={element.name}
            onConfirm={handleConfirm}
            onCancel={handleCancel} 
          />
        
        </td>
        <td width={"150px"}>
          {domains && domains.length > 0 && (
            <Autocomplete
              placeholder="Select domain"
              itemComponent={DomainItem}
              value={form.values.domain || ""}
              onChange={(val) => {
                form.setFieldValue("domain", val);
              }}
              onItemSubmit={(item) => {
                form.setFieldValue("domain", item.name);
                updateDomain(form.values, element._id, item.name);
              }}
              data={domains}
              nothingFound={<Text>No domains were found...</Text>}
              filter={(value, item) =>
                (item.name &&
                  item.name.toLowerCase().includes(value.toLowerCase().trim())) ||
                (item.value &&
                  item.value.toLowerCase().includes(value.toLowerCase().trim()))
              }
              error={form.errors.ownerName || form.errors.ownerId}
            />
          )}
        </td>

        <td width={"60px"}>
          {onEdit ? (
            <Button
              variant="subtle"
              color="dark"
              aria-label="Save"
              onClick={() => handleSave(element._id, form.values)}
              disabled={form.values.name.length > 0 ? false : true}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="subtle"
              color="dark"
              aria-label="Edit"
              onClick={() => handleEdit(element._id)}
            >
              Edit
            </Button>
          )}

          <Button
            variant="subtle"
            color="dark"
            leftIcon={<SourceIcon sourceName={"bin"} />}
            aria-label="Remove"
            onClick={() => handleRemove(element._id)}
          >
            Remove
          </Button>
        </td>
      </tr>
      <AreaReorderModal 
        openAreaReorderModal={openAreaReorderModal}
        setOpenAreaReorderModal={setOpenAreaReorderModal}
        handleToggleIndentation={handleToggleIndentation}
        setChildItem={setChildItem}
        childItem={childItem}
        elements={elements}
        element={element}
        setLoading={setLoading}
      />
    </>
  );
}

export default RowItem;